/* eslint-disable no-restricted-globals */
import { AwsGenerationInput, AwsGenerationOutput, AwsResource, AwsService, ConstructType, OutputFormat, StackGroupingType, UseCase } from '@amzn/alkimia-model';
import {
  Alert,
  Button,
  Container,
  ExpandableSection,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react-v3';
import { OptionDefinition } from '@amzn/awsui-components-react-v3/polaris/internal/components/option/interfaces';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { userSelection } from '../../../api/calls/userSelection';
import { displayErrorMessage, formatToAwsService, getStage } from '../../../utils';
import { LIST_OF_REGIONS } from '../../../utils/Constants';
import { FEATURE_USE_CASE_SELECTION } from '../../../utils/features';
import { ItemType } from './Types';

type SelectedOption = {
  label: string;
  value: string;
};

const useCaseOptions: SelectedOption[] = [
  { label: 'Use CDK to deploy to new region', value: 'create' },
  { label: 'Bring selected under CDK management', value: 'represent' },
];

const stackGroupingTypeOptions: SelectedOption[] = [
  { label: 'Dependency clustering', value: 'cluster' },
  { label: 'Single stack', value: 'single' },
];

type AlkimiaResourceSelectionProps = {
  accountLabel: string,
  errorMessages: string[],
  regionID: string,
  selectedTargetRegion: SelectedOption,
  selectedItems: any,
  targetAccountID: string,
  token: CognitoToken,
  clusters: Map<string, ItemType[]>,
  settargetAccountID: React.Dispatch<React.SetStateAction<string>>,
  setselectedTargetRegion: React.Dispatch<React.SetStateAction<SelectedOption>>,
  setAwsGenerationOutput: React.Dispatch<React.SetStateAction<AwsGenerationOutput>>,
}

/**
 * Child component of Resource Selection page. Component to handle other optional items a customer may need for their migration
 * @param accountLabel - used to display the account id on the resource selection page
 * @param regionID - Source Region customer selected on Home Page
 * @param selectedTargetRegion - Target Region customer wants to migrate to
 * @param targetAccountID - Target Account customer wants to migrate to
 * @param errorMessages - list of error messages that occured during scanning the AwsAccount
 * @param blockMsg - messages blocking customer from accessing ResourceSelection page
 * @returns Options Component
 */
function AdvancedOptionsComponent({
  accountLabel,
  regionID,
  selectedTargetRegion,
  targetAccountID,
  errorMessages,
  selectedItems,
  token,
  clusters,
  settargetAccountID,
  setselectedTargetRegion,
  setAwsGenerationOutput,
}: AlkimiaResourceSelectionProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [blockMsg, setBlockMsg] = useState('');
  const [targetRegion, setTargetRegion] = useState<string>('us-east-1');
  const [selectedUseCase, setSelectedUseCase] = useState<SelectedOption>(useCaseOptions[0]);
  const [targetDisbale, setTargetDisable] = useState<boolean>(false);
  const [stackGroupingType, setStackGroupingType] = useState<StackGroupingType>('single');
  const [regionOptions, setregionOptions] = useState<SelectedOption[]>(LIST_OF_REGIONS);
  const history = useHistory();
  const disableTargetOptionsForImport = (selectedOption: OptionDefinition) => {
    setTargetDisable(selectedOption.value === 'represent');
  };

  return (
    <>
      <div style={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        <div style={{ flex: 1 }}>
          <b>{`Resources discovered from account '${accountLabel}' in region '${regionID}'`}</b>
        </div>
        <Button
          ariaLabel="Feedback"
          iconAlign="right"
          href="https://sim.amazon.com/issues/create?template=da0d26a2-abe4-439e-9f67-324ea1b57b01"
          variant="link"
          iconName="external"
          target="_blank"
        >
          Provide Feedback
        </Button>
      </div>
      <Alert visible={isBlocked} type="error">
        {`${blockMsg}`}
      </Alert>
      {displayErrorMessage(errorMessages, 'Some resources from the above services may be missing, but feel free to proceed.')}
      <Container>
        <Header
          variant="h3"
          actions={
            (
              <Button
                loading={isProcessing}
                variant="primary"
                iconAlign="right"
                disabled={isBlocked || selectedItems?.length === 0}
                onClick={async (event) => {
                  setIsProcessing(true);
                  event.preventDefault();
                  let blocked = false;
                  let blockedMsg = '';
                  const accountId = accountLabel.split(':')[0];
                  const awsGenerationInput: AwsGenerationInput = {
                    awsServices: formatToAwsService(clusters, selectedItems),
                    outputFormat: OutputFormat.JSON,
                    options: {
                      awsAccountId: accountId,
                      awsRegion: regionID,
                      targetAwsAccountId: targetAccountID,
                      targetAwsRegion: targetRegion,
                      cdkConstructType: ConstructType.DEFAULT,
                      useCase: selectedUseCase.value as UseCase,
                      stackGrouping: 'single', // for now we will always use single stack grouping
                      outputType: 'ZIP',
                      // This parameter is currently not used in AlkimiaService
                      // TODO: Update to take in user input
                      projectName: '',
                    },
                  };
                  try {
                    const awsGenerationOutput = await userSelection(token, awsGenerationInput);
                    setAwsGenerationOutput(awsGenerationOutput);
                  } catch (e: any) {
                    blocked = true;
                    blockedMsg = 'Unable to send request to server. Please try again';
                  }
                  setIsProcessing(false);
                  if (blocked) {
                    setIsBlocked(true);
                    setBlockMsg(blockedMsg);
                  } else {
                    history.push('/output');
                  }
                }}
              >
                Generate CDK template
              </Button>
            )
          }
        >
          You can update Advanced Options to customize the generated CDK templates.
        </Header>
        <ExpandableSection
          headerText="Advanced Options"
          variant="navigation"
        >
          <SpaceBetween size="s" direction="horizontal">
            {FEATURE_USE_CASE_SELECTION[getStage()] && (
              <FormField
                description="This feature is under development"
                label="Use case"
              >
                <Select
                  selectedOption={selectedUseCase as OptionDefinition}
                  onChange={({ detail: { selectedOption } }) => {
                    setSelectedUseCase(selectedOption as SelectedOption);
                    disableTargetOptionsForImport(selectedOption);
                  }}
                  options={useCaseOptions}
                  filteringType="auto"
                />
              </FormField>
            )}
            <FormField
              label="Target AWS account ID"
            >
              <Input
                disabled={targetDisbale}
                value={targetAccountID}
                onChange={(event) => settargetAccountID(event.detail.value)}
                placeholder="Enter export account ID"
              />
            </FormField>
            <FormField
              label="Target AWS region"
            >
              <Select
                disabled={targetDisbale}
                selectedOption={selectedTargetRegion as OptionDefinition}
                onChange={({ detail: { selectedOption } }) => {
                  setselectedTargetRegion(selectedOption as SelectedOption);
                  setTargetRegion(selectedOption.value as string);
                }}
                options={regionOptions}
                filteringType="auto"
              />
            </FormField>
          </SpaceBetween>
        </ExpandableSection>
      </Container>
    </>
  );
}

export const AlkimiaAdvancedOptionsComponent = AdvancedOptionsComponent;
