import { DiscoverAwsResourceConfigurationOutput, ScanOperations } from '@amzn/alkimia-model';
import { CognitoToken } from 'amazon-cognito-auth-ts';
import axios from 'axios';
import {
  constructQueryString,
  getLambdaApiUrl,
  getStandardHeader,
  processApiError,
  enableMockResponses,
  mockScanAwsAccountResponse,
  API_RETRY_INTERVAL_IN_MS,
  API_TIMEOUT_INTERVAL_IN_MS,
  SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS,
} from '@amzn/alkimia-react-components';

const mock = enableMockResponses;

export const scanAwsAccount = async (
  token: CognitoToken,
  customerAccountId: string | undefined,
  customerAccountRegion: string,
): Promise<DiscoverAwsResourceConfigurationOutput> => {
  if (!customerAccountId) throw new Error('Missing required input - awsAccountId');
  if (mock) { return Promise.resolve(mockScanAwsAccountResponse); }
  return new Promise((resolve) => {
    const startTime = Date.now();
    let scanOperation = ScanOperations.startScan;
    const intervalRetry = setInterval(() => {
      axios.get(`${getLambdaApiUrl()}/datastore/scanAwsAccount?${constructQueryString({ customerAccountId, customerAccountRegion, scanOperation })}`,
        {
          headers: getStandardHeader(token),
          timeout: API_TIMEOUT_INTERVAL_IN_MS,
        })
        .then((result) => {
          if (result.status === 200) {
            clearInterval(intervalRetry);
            resolve({ messages: [], services: result.data, mergedServices: [] });
          }
          if (Date.now() - startTime > SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS * 1000) {
            clearInterval(intervalRetry);
            resolve({ messages: [`Timed out after ${SCANAWSACCOUNT_LAMBDA_TIMEOUT_IN_SECONDS} seconds`], services: result.data ?? [], mergedServices: [] });
          }
          if (scanOperation === ScanOperations.startScan) {
            scanOperation = ScanOperations.getScan;
          }
        })
        .catch((error) => {
          if (error.response.status === 403) {
            clearInterval(intervalRetry);
            resolve({ messages: [error.response.data.message], services: [], mergedServices: [] });
          }
          resolve(processApiError('scanAwsAccount', error));
        });
    }, API_RETRY_INTERVAL_IN_MS);
  });
};
